
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IRegraPreco, IRegraPrecoRegiao, IRegraPrecoEstado } from '@/models/Entidades/IRegraPreco';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import EditorHtml from '@/core/components/UI/EditorHtml.vue';
import ServicoRegraPreco from '@/servicos/ServicoRegraPreco';
import { IResposta } from '@/core/models/IResposta';
import CampoNumerico from '@/core/components/UI/CampoNumerico.vue';
import SelecionarData from '@/core/components/UI/SelecionarData.vue';
import ComboAplicacaoRegraPreco from '@/components/RegrasPrecos/ComboAplicacaoRegraPreco.vue';
import ComboFinalidadeProdutos from '@/components/ComboFinalidadeProdutos.vue';
import ComboTipoClientes from '@/components/ComboTipoClientes.vue';
import ComboRegiao from '@/components/ComboRegiao.vue';
import ComboEstado from '@/components/ComboEstado.vue';
import CaixaSelecao from '@/core/components/UI/CaixaSelecao.vue';

import { EAplicacaoRegraPreco } from '@/models/Enumeradores/EAplicacaoRegraPreco';
import { EFinalidadeProdutos } from '@/models/Enumeradores/EFinalidadeProdutos';
import { ETipoCliente } from '@/models/Enumeradores/ETipoCliente';
import { ETipoRegraPreco } from '@/models/Enumeradores/ETipoRegraPreco';
import { ICaixaSelecao } from '@/core/models/Tela/ICaixaSelecao';

export default defineComponent({
  name: 'RegraPrecoCadastro',
  components: {
    ComunicacaoApi,
    EditorHtml,
    CampoNumerico,
    SelecionarData,
    ComboAplicacaoRegraPreco,
    ComboFinalidadeProdutos,
    ComboTipoClientes,
    ComboRegiao,
    ComboEstado,
    CaixaSelecao,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarRespostaRedirecionamento, obterCodigoRota,
    } = useAppBase();
    const servicoRegraPreco = new ServicoRegraPreco();

    const state = reactive({
      regraPreco: {} as IRegraPreco,
      regioesSelecionadas: [] as number[],
      estadosSelecionados: [] as number[],
      opcoesCaixaSelacao: [] as ICaixaSelecao[],
    });

    function montaCaixaSelecao() {
      state.opcoesCaixaSelacao = [
        { valor: ETipoRegraPreco.Geral, Titulo: 'Geral', descricao: 'Aplicar acréscimos/descontos em todos os produtos independente do estado ou região.' },
        { valor: ETipoRegraPreco.Estado, Titulo: 'Por Estado', descricao: 'Aplicar acréscimos/descontos no preço do produto baseado no estado do cliente.' },
        { valor: ETipoRegraPreco.Regiao, Titulo: 'Por Região', descricao: 'Aplicar acréscimos/descontos no preço do produto baseado na região do cliente.' },
      ];
    }
    async function obterRegraPreco(codigo:number) {
      appBase.carregando = true;
      state.regraPreco = await servicoRegraPreco.obter(codigo);

      state.regioesSelecionadas = [];
      if (state.regraPreco.regioes.length > 0) {
        state.regraPreco.regioes.forEach((regiao) => {
          state.regioesSelecionadas.push(regiao.codigoRegiao);
        });
      }

      state.estadosSelecionados = [];
      if (state.regraPreco.estados.length > 0) {
        state.regraPreco.estados.forEach((estado) => {
          state.estadosSelecionados.push(estado.codigoEstado);
        });
      }
      appBase.carregando = false;
    }

    function limparDados() {
      state.regioesSelecionadas = [];
      state.estadosSelecionados = [];
      state.regraPreco = {} as IRegraPreco;
      state.regraPreco.codigo = 0;
      state.regraPreco.status = true;
      state.regraPreco.tipo = ETipoRegraPreco.Geral;
      state.regraPreco.tipoCliente = ETipoCliente.AmbosPessoaFisicaJuridica;
      state.regraPreco.finalidadeProdutos = EFinalidadeProdutos.AmbosRevendaRevendaConsumo;
      state.regraPreco.aplicacao = EAplicacaoRegraPreco.AcrescimoPercentual;
      state.regraPreco.regioes = [];
      state.regraPreco.estados = [];
    }

    onBeforeMount(async () => {
      montaCaixaSelecao();
      limparDados();
      const codigoRegraPreco = obterCodigoRota();
      if (codigoRegraPreco > 0) {
        await obterRegraPreco(codigoRegraPreco);
      }
    });

    function preparaPersistenciaEstados() {
      const estados : IRegraPrecoEstado[] = [];
      if (state.estadosSelecionados.length > 0) {
        state.estadosSelecionados.forEach((codigoEstado) => {
          const estadoExistente = state.regraPreco.estados.find((c) => c.codigoEstado === codigoEstado);
          if (estadoExistente !== undefined) {
            estados.push(estadoExistente);
          } else {
            const estado: IRegraPrecoEstado = {
              codigo: 0, codigoRegraPreco: state.regraPreco.codigo, codigoEstado,
            };
            estados.push(estado);
          }
        });
      }
      state.regraPreco.estados = estados;
    }

    function preparaPersistenciaRegioes() {
      const regioes : IRegraPrecoRegiao[] = [];
      if (state.regioesSelecionadas.length > 0) {
        state.regioesSelecionadas.forEach((codigoRegiao) => {
          const regiaoExistente = state.regraPreco.regioes.find((c) => c.codigoRegiao === codigoRegiao);
          if (regiaoExistente !== undefined) {
            regioes.push(regiaoExistente);
          } else {
            const regiao: IRegraPrecoRegiao = {
              codigo: 0, codigoRegraPreco: state.regraPreco.codigo, codigoRegiao,
            };
            regioes.push(regiao);
          }
        });
      }
      state.regraPreco.regioes = regioes;
    }

    async function salvar() {
      appBase.resposta = {} as IResposta;
      preparaPersistenciaRegioes();
      preparaPersistenciaEstados();
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações da Regra de Preço.');

      if (state.regraPreco.codigo === 0) {
        appBase.resposta = await servicoRegraPreco.incluir(state.regraPreco);
      } else {
        appBase.resposta = await servicoRegraPreco.atualizar(state.regraPreco);
      }

      apresentarRespostaRedirecionamento('RegrasPrecos');
    }

    return {
      appBase,
      state,
      salvar,
      ETipoRegraPreco,
      EAplicacaoRegraPreco,
    };
  },
});
